.ui.table.borderTop {
    border-top: 1px solid rgba(34, 36, 38, 0.1);
}

.ui.table thead th {
    font-size: 16px;
}

.ui.table thead.secondTableHeader th {
    background: @voyaLightGray!important;
    color: @voyaDarkOrange!important;
    border-radius: 0!important;
    padding: 0.5em 0.78571429em !important;
    border-bottom: none;
}

.categoryDescription p {
    font-size: @smallerFontSize;
    font-weight: 600;
    color: @neutral700;
    line-height: 130%;
}

.categoryDescription .ui.header {
    margin-bottom: 0.4rem;
}

.categoryIncrements {
    font-size: @smallFontSize;
}

.ui.table.employerContributionTable td:first-child {
    color: @neutral700;
    font-weight: 600;
}


/* --- EMPLOYER / PROPOSAL --- */

.ui.table.monthlyCostTable {
    border: 1px solid #E6B8A7;
}

.monthlyCostTableContainer:before {
        content:"";
        display:inline-block;
        border-top: 1px solid #E6B8A7;
        border-right: 1px solid #E6B8A7;
        background: #FFEFEA;
        width: 10px;
        height: 10px;
        left: 10%;
        top: 12px;
        position: relative;
        z-index: 1;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
  }

.ui.table.monthlyCostTable table {
    border: none;
    font-size: @smallerFontSize;
}

.ui.table.monthlyCostTable td:first-child {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.ui.table.monthlyCostTable thead th {
    border: none;
    border-bottom: 1px solid #E6B8A7!important;
    background: #FFEFEA!important;
    color: @neutral900!important;
    text-align: center!important;
}

.ui.table.benefitsCategoriesTable{
    border: 1px solid @voyaLightGray;
}

.ui.table.benefitsCategoriesTable td{
    border: none;
}

.ui.table.benefitsCategoriesTable tr:not(:first-child) td{
    padding-top: 0!important;
}

.ui.table.benefitsCategoriesTable th{
    background: @voyaLightGray;
}

.ui.table.noBorders,
.ui.table.noBorders td {
    border: none;
}

.ui.table > tbody > tr.coverageTerminationMembers > td{
    border-top: 1px solid rgba(67, 128, 108, 0.5);
    border-bottom: 1px solid rgba(67, 128, 108, 0.5);
    font-size: 14px;
}

.ui.table > tbody > tr.coverageTerminationMembers td:first-child{
    border-left: 1px solid rgba(67, 128, 108, 0.5);
    border-radius: 8px 0 0 8px;
}

.ui.table > tbody > tr.coverageTerminationMembers td:last-child{
    border-right: 1px solid rgba(67, 128, 108, 0.5);
    border-radius: 0 8px 8px 0;
}

.ui.basic.table.adminsTable {
    margin-top: 24px;
    width: 886px;
    margin-left: auto;
    margin-right: auto;
}

.ui.basic.table.adminsTable td {
    word-break: break-word!important;
}

.ui.basic.table.adminsTable td:nth-child(1), th:nth-child(1){
    width: 180px;
}

.ui.basic.table.adminsTable td:nth-child(2), th:nth-child(2){
    width: 180px;
}

.ui.basic.table.adminsTable th:hover {
    font-weight: 700;
    color: @neutral700;
    cursor: default;
}
