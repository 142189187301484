/*******************************
         Site Overrides
*******************************/

h1, h1.ui.header, h2, h2.ui.header {
    line-height: 130%;
}

h3, h3.ui.header, h4.ui.header, h4 {
    line-height: 150%;
}

.ui.header {
    margin: 1rem 0;
    color: @primaryHeaderColor;
}

.ui.header.employerMyInfoHeadder {
    margin-top: 24px;
    margin-bottom: 16px;
}

.ui.header.adminsTableHeader {
    margin: 0 !important;
}

.ui.header.loginMainHeader {
    position: relative;
    color: @white!important;
    @media not screen and (min-width: 1024px) {
        max-width: 340px;
        padding-top: 57px;
        font-size: 32px;
        margin-top: 0;
        color: @voyaDarkOrange!important;
    }
}

.loginContent .ui.header {
    margin-bottom: 0;
    margin-top: 0;
}