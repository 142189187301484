/*******************************
         Site Overrides
*******************************/
.ui.modal > .close {
  top: 0.7rem;
  right: 0.7rem;
  color: #939393;
}

.ui.modal > .actions {
  border: 0;
  background: none;
}

.ui.modal.removeAdminUserModal {
    height: 296px;
    width: 525px;
    border-radius: 8px;
}

.ui.modal > .header.customModalHeader{
    display:flex;
    justify-content: space-between;
    align-content: center;
    font-size: 1rem!important;
    color: @neutral900;
    line-height: 24px;
    padding: 1rem 1.5rem;
    border-color: @neutral300;
    & > span {
        margin: 0;
    }
}