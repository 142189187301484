/*******************************
         Site Overrides
*******************************/

html,
body {
    height: 100%;
    position: relative;
    overscroll-behavior-y: none;
}

.truncate-3 {
display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3;
   overflow: hidden;
   }

.headerContainer {
    background: white;
    box-shadow: 0px 4px 4px rgba(220, 230, 229, 0.5)!important;
    margin-bottom: 50px;
    padding: 18px 1em;
}

.menuUserDropdown .text {
    color: @linkColor;
    font-size: @fontSize!important;
    font-weight: bold;
}

.menuUserDropdown .text:hover {
    color: @voyaDarkBlue;
}

.menuUserDropdown .menu.transition.left.visible{
    z-index: 101;
}

.ui.ui.ui.ui.dropdown.getHelpMenu .item {
    font-size: 14px !important;
    line-height: 1.5;
    padding: 5px 18px !important;
}

.ui.ui.ui.ui.dropdown.getHelpMenu .item a {
    font-size: 14px;
}

.ui.ui.ui.ui.dropdown.getHelpMenu .phoneLink {
    color: @neutral900;
}

.ui.ui.ui.ui.dropdown.getHelpMenu .phoneLink:hover {
    text-decoration: underline;
}

.pageContent {
    padding-left: 0!important;
    padding-right: 0!important;
}

a,
.linkText {
    font-weight: 600;
    color: @linkColor;
}

a:hover
.link:hover,
.linkText:hover {
    cursor: pointer;
}

.linkText.cancelLink {
    font-size: 14px !important;
}

.alternativeLinkText {
    color: #5F9B1B;
    font-weight: bold;
}

.alternativeLinkText:hover {
    cursor: pointer;
}

.requiredField:after {
    display: inline-block;
    vertical-align: top;
    margin: -0.2em 0em 0em 0.2em;
    content: '*';
    color: #DB2828;
}

.centerAligned {
    text-align: center;
}

.clickable:hover {
    cursor: pointer;
}

.hoverable {
    cursor: pointer;
}

/* --- BODY FONT HEADERS --- */
.strategicPartnerDisclaimer {
    font-size: 12px;
    color: @neutral700;
    text-align: justify;
    margin-bottom: 25px !important;
    padding: 0 5px !important;
    user-select: none;
}

.colorfulText {
    color: @voyaDarkOrange !important;
    font-weight: bold;
}

.colorfulCardHeader {
    background-color: @voyaDarkOrange !important;
    color: white !important;
    padding-top: 18px !important;
}

/* --- FONT SIZE --- */

.small {
    font-size: @smallFontSize !important;
    line-height: 150%!important;
}

.smaller {
    font-size: @smallerFontSize;
    line-height: 150%!important;
}

.bold {
    font-weight: bold;
}

/* --- FONT COLOR --- */

.primaryText {
    color: @voyaDarkBlue;
}

.secondaryText {
    color: @voyaDarkOrange;
}

.colorfulIcon {
    color: @voyaDarkBlue;
}

.neutral600Text {
    color: @neutral600!important;
}

.neutral700Text {
    color: @neutral700!important;
}

.neutral900Text {
    color: @neutral900!important;
}

.eucalyptusText {
    color: @eucalyptus;
}

.warningRedText {
    color: @warning900!important;
}

.sunshade {
    color: @sunshade!important;
}

.peach {
    color: @peach!important;
}

/* --- BACKGROUND COLOR --- */
.bkgLinen {
    background-color: @linen !important;
}

.bkgNeutral100 {
    background-color: @neutral100!important;
}
/* --- STEPS --- */

.steps .ui.grid .column{
    padding-left: 0!important;
    padding-right: 0!important;
    font-weight: 600;
}

.step {
    display: table;
    white-space: nowrap;
    height: auto;
    margin: '';
    line-height: 1;
    text-align: center;
}

.step>.ui.label {
    font-size: 1em;
}

.step>.lineContainer {
    content: '';
    display: table-cell;
    position: relative;
    width: 100%;
    vertical-align: middle;
}

.step>.lineContainer>.greyLine {
    height: 2px;
    background-color: @neutral300;
}

.step>.lineContainer>.colorLine {
    height: 2px;
    background-color: @voyaDarkBlue;
}

.step>.ui.basic.label {
    border-width: 2px!important;
    line-height: .5em!important;
}

.step>.ui.basic.grey.label {
    border-color: @neutral300;
}

.step>.ui.green.labels .label, .step>.ui.green.label {
    background-color: @voyaDarkBlue !important;
    border-color: @voyaDarkBlue !important;
    color: #fff !important;
}

.step>.ui.grey.labels .label, .step>.ui.grey.label {
    background-color: #ddd !important;
    border-color: #ddd !important;
    color: #fff !important;
}

.disabledStep {
    color: @neutral700;
    font-weight: normal;
}


/* --- FOOTER --- */

.footer {
    background-color: @neutral50;
    border-color: @neutral200;
    border-top: 1px solid @neutral200;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin-top: 1em;
    z-index: 999;
    position: fixed;
    padding: 0 20px;
    font-size: 10px !important;
    color: @neutral700;
    padding-bottom: 8px;
    left: 0;
}

.footer.accountCreation {
    position: fixed!important;
}

.footer .buttonContainer {
    border-bottom: 1px solid @neutral200;
}

.footer .buttonContainer .grid {
    margin: 16px;
}

.footer .buttonContainer .column {
    padding: 0 !important;
}

.footer .carrierDisclaimers {
    text-align: left;
    margin: 0px 5px;
}

.footer .ui.grid > .column:not(.row) {
    padding-bottom: 0px !important;
}

.footer .ui.text.menu {
    margin: 0 !important;
}

.footer .ui.text.menu .item {
    color: @neutral700!important;
    font-size: 10px !important;
    padding-bottom: 0px;
    margin-bottom: 16px;
}

.footer .ui.text.menu.termsPrivacy .item {
    font-weight: 700 !important;
    color: @neutral500!important;
}

/* --- APPOINTMENT --- */

.appointment-main-container {
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: @footerHeight;
    padding-top: 0px;
}


/* --- EMPLOYER --- */

.employer-main-container {
    height: 100vh; /* will cover the 100% of viewport */
    overflow: auto;
    display: block;
    position: relative;
}

.employer-portal-main-container {
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    margin-bottom: @footerHeight;
}


.employer-createPassword-container {
    min-height: 75vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 70px;
}


.employer-portal-container {
	min-height: 100vh; /* will cover the 100% of viewport */
	overflow: hidden;
	display: block;
	position: relative;
	padding-bottom: 140px; /* height of your footer */
	padding-top: 60px;
	background: @neutral50;
}


.employer-portal-headerContainer {
    background: white!important;
    box-shadow: 0px 4px 4px rgba(220, 230, 229, 0.5)!important;
    margin: 0;
    padding: 10px;
    border-bottom: none!important;
}

.employer-createAccount-headerContainer {
    background: white;
    box-shadow: 0px 4px 4px rgba(220, 230, 229, 0.5)!important;
    padding: 18px 1em;
    position: fixed;
    width: 100%;
    z-index: 200;
}

.employer-myInfoContainer-infoContainer {
    display: flex;
    flex-direction: row;
}

.employer-myInfoContainer-infoItem {
    width: 33.33%;
}

.employer-myInfoContainer-infoLabel {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #757575;
    margin-bottom: 4px;
}

.employer-myInfoContainer-infoValue {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #212121;
    margin-top: 0;
    word-break: break-all;
}

.employer-myInfoContainer-infoItem-notFirst {
    margin-left: 24px;
}

.employer-headerContainer {
    margin-top: 24px;
}

.employer-account-flexHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.employer-accountContainer {
    display: flex;
    justify-content: center;
    margin-top: 56px;
}

.employer-smallModalMainContent {
    height: 144px;
    word-break: break-word;
}

.termination-details-subTitle {
    text-align: center;
    display: block;
    margin-bottom: 40px;
}

.termination-review-subTitle {
    display: block;
    margin-bottom: 40px;
}


/* --- ENROLLMENT --- */

.enrollment-main-container {
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: @footerHeight;
}

.contentContainer {
    padding-bottom: 10em;
}


/* --- MEMBER --- */

.loginContainer {
    padding-top: 130px;
    height: 100%;
}

.member-main-container {
 min-height: 100vh; /* will cover the 100% of viewport */
 overflow: hidden;
 display: block;
 position: relative;
 padding-bottom: @footerHeight;
 padding-top: 140px;
}

.member-headerContainer {
    margin-bottom: 50px;
    padding: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 101;
    box-shadow: 0px 4px 4px rgba(220, 230, 229, 0.5);
}

.header-shadow {
    box-shadow: 0px 2px 2px 2px rgba(220, 230, 229, 0.5);
    position: fixed;
    top: 64px;
    width: 100%;
    height: 1px;
    z-index: 100;
    background: white;
}

.googlePlacesField input {
    height: 50px;
}

/* --- LEFT NAVIGATION --- */
.leftNavigation {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    width: 220px;
    overflow-x: hidden;
    flex: 1;
    z-index: 103;
    background: white;
    padding: 1em 1.5em;
    border-right: 1px solid #E0E0E0;
}

.leftNavigation .item.navigationItem {
    padding: .9em 0!important;
    font-size: @smallFontSize;
}

.leftNavigation .item.navigationItem .icon {
    margin-right: .5rem;
}

.billIconExclamation {
    margin-left: 5px;
}

/* --- INTERNAL MENU --- */

.ui.text.menu.internalMenu {
    margin: 0;
}

.ui.text.menu.internalMenu .item {
    border-bottom: 4px solid transparent;
    color: @neutral600;
    margin-right: 2em;
}

.ui.text.menu.internalMenu .item.active {
    border-bottom: 4px solid @voyaDarkBlue;
    color: @neutral900;
    font-weight: 700;
}

.ui.text.menu.internalMenu.removePaddingSides .item.active {
    padding-right: 0px;
    padding-left: 0px;
}

/* Alerts */
.billingAlert {
    padding: .6em;
    text-align: center;
    font-size: @smallerFontSize;
    z-index: 101;
    position: fixed;
    width: calc(100% - 220px);
}

.billingAlert.preview-available  {
    background-color: @linen;
    color: #856404;
}

.billingAlert.preview-available a{
    color: @neutral900;
}

.billingAlert.due {
    background-color: @sunshade;
    color: #fff;
}

.billingAlert.past-due  {
    background-color: @warning900;
    color: #fff;
}

.billingAlertDate {
    color: black;
}

.contractGroupStatusAlert {
  padding: .6em;
  text-align: center;
  font-size: @smallerFontSize;
  z-index: 101;
  position: fixed;
  width: calc(100% - 220px);
  margin-top: 2px;
}

.contractGroupStatusAlert.draft,
.contractGroupStatusAlert.inApproval,
.contractGroupStatusAlert.voided,
.contractGroupStatusAlert.expired,
.contractGroupStatusAlert.terminated {
  background-color: #FFF7EC;
  color: #212121;
}

.cp-download-msg {
    min-height: 40px;
    margin-top: 8px;
    width: 90%;
}

.textTtotalCost {
    color: @voyaDarkOrange;
}

.filterText {
 color: @voyaDarkBlue !important;
 font-weight: bold;
}

.filterText:hover {
    color: @voyaDarkBlue!important;
}

.downloadIcon {
    position: absolute;
    top: 14px;
    right: 6px;
}

.downloadIconColor {
    color: #145A7B;
}

.eyePreviewIcon {
  font-size: 24px !important;
  background-color: @voyaPlum;
  color: #fff;
  border: none;
  box-shadow: none !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.mfaFlowFooter .ui.fluid.container{
    padding: 0 20px 0;
}

.accountCreation .ui.fluid.container{
    padding: 0 20px;
}

.strategicPartnerLogo{
    position: relative!important;
    top: 0rem;
}

.benadmin-segment-nextstep {
    padding-top: 100px!important;
    padding-bottom: 100px!important;
}

.benadmin-segment-nextstep img {
    width: 96px!important;
    margin: 0 auto 16px!important;
}

.attachment-area-box {
    padding-top: 30px!important;
    padding-bottom: 31px!important;
}

.segmentContainerRender {
    min-height: 397px!important;
    display: flex!important;
    flex-direction: column!important;
}

.segmentRender {
    flex: 1!important;
    display: flex!important;
    align-items: center!important;
}

.bgLightGray {
    background-color: @voyaLightGray!important;
}

.textError {
    color: #d82000;
    font-size: 12px;
    line-height: 21px;
    text-align:left;
}

.circularIconExclamation {
    font-size: 8px!important;
    margin-bottom: 2px!important;
    margin-right: 0px!important;
}

.warningText {
    display: inline-block;
    line-height: 120%;
}

.renderNotAvailableContainer {
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    padding: 24px!important;
}

.renderNotAvailableContainer.short {
    height: 139px!important;
}

.renderNotAvailableContainer.tall {
    height: 223px!important;
}

.renderNegativeBillingGrid {
    margin: auto!important;
    padding-top: 0px!important;
}

.creditContainer {
    background-color: #FFFFFF!important;
    padding: 8px 16px!important;
    color: #616161!important;
    border: 1px solid #E0E0E0!important;
    border-radius: 8px!important;
    display: flex!important;
    margin-top: 35px!important;
    line-height: 1!important;
}

.floatLeft {
    float: left!important;
}

.msgAccount {
    color: @neutral700;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Raleway;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.msgAccount b{
    font-weight: 600;
}

.suicr-year-view-header, .suicr-month-view-header, .suicr-day-view-header> {
    tr > th {
        background: @calendarBackground!important;
        color: @calendarColor!important;
    }
}

.loginLayout {
    display: flex;
    @media not screen and (min-width: 1024px) {
        flex-direction: column;
    }
    @media screen and (min-width: 1024px) {
        flex-direction: row;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
}

.loginLoading {
    position: relative;
    pointer-events: none;
    user-select: none;
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: rgba(255, 255, 255, .5);
    }
}

.loginInfoPanelLayout {
  color: @white;
  height: 100%;
  overflow: auto;
  @media not screen and (min-width: 1024px) {
    width: 100%;
    padding: 50px 20px 0;
    max-width: 516px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 1024px) {
    padding: max(8.4vh, 20px) 65px 20px;
    width: 50%;
    max-width: 730px;
    background: url(https://brella-platform-assets.s3.amazonaws.com/voya/employer/login_background.png) no-repeat center center @voyaDarkOrange;
    background-size: cover;
    color: @white;
  }
}

.loginBrand {
    width: 100%;
    display: block;
    min-height: 80px;
    max-width: 258px;
}

@media not screen and (min-width: 1024px) {
    .loginBrand > img:first-child  {
        display: none !important;
    }
}
@media screen and (min-width: 1024px) {
    .loginBrand > img:last-child {
        display: none !important;
    }
}

.loginInfoLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 0;
    @media screen and (min-width: 1024px) {
        padding: 20px 0 180px;
    }
}

.loginWelcome {
    margin-top: 16px;
    font-size: 28px;
    line-height: 1.2;
    @media not screen and (min-width: 1024px) {
        display: none;
    }
}

.loginContentPanel {
    display: flex;
    flex-direction: column;
    overflow: auto;
    @media not screen and (min-width: 1024px) {
        flex: 1 1 auto;
    }
    @media screen and (min-width: 1024px) {
        flex: 1;
        height: 100vh;
    }
}

.loginContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 100%;
    max-width: 576px;
    padding: 20px 30px;
    margin: auto;
    @media not screen and (min-width: 1024px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        max-width: 516px;
    }
}

.loginFooter {
    width: 100%;
    max-width: 576px;
    padding-left: 30px;
    padding-right: 30px;
    margin: auto;

    & .ui.grid {
        margin-top: 0;
        margin-bottom: 0;
    }
    & .ui.text.menu {
        font-size: 12px!important;
        line-height: 1.5;
    }
    & .ui.menu .item {
        color: @neutral700!important;
    }
    & .ui.menu .right .item {
        font-weight: 700;
        color: @neutral500!important;
    }
    @media not screen and (min-width: 1024px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        max-width: 516px;
        margin: 50px auto 0;
    }
}

.bkgNeutral50 {
    background: @neutral50!important;
}

.bkgNeutral100 {
    background: @neutral100!important;
}

.noVerticalMargin {
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.mfaMethodsOrDivider {
    margin: 24px auto;
    margin-left: 30px
}

.mfaMethodTitle {
    margin-bottom: 0;
    font-weight: 700;
}

.mfaMethodTitleDisabled {
    color: @neutral600;
    font-weight: 400;
}

.mfaMethodComingSoon {
    background-color: @neutral100;
    width: 106px;
    height: 26px;
    color: @black;
    padding: 4px 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    border-radius: 100px;
}

.backupCodeText {
    max-width: 355px;
	padding: 0 24px!important;
	margin-bottom: 16px!important;
	text-align: left;
}

.mfaVerified {
    display: inline-block;
    color: @eucalyptus;
    font-weight: 700!important;
}

.mfaVerified > .icon {
    margin-right: 0.5rem;
}

.mfaButtonsContainer > .button:first-child {
    margin-right: 1.5rem;
}

.mfaSectionDivider {
    border-top: 1px solid @neutral300;
    padding: 24px 0;
}

.mfaMainContainer {
    overflow: hidden;
    @media (max-height: 500px) {
        overflow-y: scroll;
    }
}