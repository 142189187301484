/*******************************
        User Overrides
*******************************/

.ui.dropdown input[type="text"]:focus {
  border-width: 0 0 3px 0;
  border-radius: 0;
  border-color: @voyaDarkBlue;
}

.ui.dropdown > .text {
    font-size: @smallFontSize;
}

.ui.selection.active.dropdown .menu {
  border-style: none !important;
}

.ui.selection.dropdown {
    border: 1px solid @neutral500;
    padding: 8px 8px!important;
    min-height: 36px;
    font-size: @smallFontSize;
    line-height: 18px;
}

.ui.readonly.selection.dropdown,
.ui.input:not(.readonly) input[readonly] {
    background: @readonlyFieldsBkg;
    border: 1px solid @readonlyFieldsBkg;
}

.benefitTerminationRules .ui.selection.dropdown>.text {
    max-width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}