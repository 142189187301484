/*******************************
         Site Overrides
*******************************/

.ui.readonly.input > input,
.ui.input:not(.readonly) input[readonly] {
    background: @readonlyFieldsBkg;
    border: 1px solid @neutral500!important;
}

.ui.form .disabled,
.ui.form .disabled.field{
    opacity: 1!important;
}

.ui.disabled.selection.dropdown,
.ui.disabled.input input {
    opacity: 1!important;
    background-color: #eeeeee;
    border-color: #c2c8c7;
}

.ui.disabled.selection.dropdown .text {
    color: @neutral600;
}

.ui.form .field.disabled > label, .ui.form .fields.disabled > label {
    opacity: 1!important;
}

.ui.form .field.no-margin-input {
    div.field {
        margin: 0;
    }
}

.ui.form .field > label {
    font-size: 14px;
}

.ui.form .fields:not(:last-child) {
    margin-bottom: 16px;
}

.ui.form .fields.error .field label,
.ui.form .field.error label {
	color: @neutral900;
}

.ui.form .field.customEmailInput{
    margin-bottom: 0px;
}

.ui.form .field.mfaMethodField {
    display: flex!important;
    cursor: pointer;
    margin: 0 auto!important;
}

.ui.form .field.mfaMethodFieldDisabled {
    display: flex!important;
    margin: 0 auto 0.5rem 0!important;
}

.ui.form .field.mfaMethodField label {
    width: 100%!important;
}

.ui.form .field.mfaMethodFieldDisabled label {
   width: 100%!important;
}

.ui.form .field.mfaVerifyCode {
    width: 300px;
    display: inline-block!important;
    margin-right: 16px!important;
}

.ui.form.mfaJustifyCenter {
    display: flex;
    justify-content: center;
}