/*******************************
         Site Overrides
*******************************/

.ui.toggle.checkbox input:checked ~ label {
color: #333 !important;
font-weight: 600;
}

.ui.checkbox.radio input:checked ~ label:before,
.ui.checkbox.radio input:checked ~ .box:before,
.ui.checkbox.radio input:checked:focus ~ label:before {
border-color: @neutral600!important;
}

/* --- APPOINTMENT --- */

.appointmentCertifyCheckboxes .ui.checkbox {
    line-height: @lineHeight!important;
    margin-bottom: 1.5em;
}

.appointmentCertifyCheckboxes .ui.checkbox input ~ label:before{
    margin-top: 3px;
}

.appointmentCertifyCheckboxes .ui.checkbox label {
    font-size: @fontSize;
}

.ui.toggle.checkbox.success input:checked ~ .box:before,
.ui.toggle.checkbox.success input:checked ~ label:before {
    background-color: #34C759 !important;
}

.ui.toggle.checkbox.success input:checked ~ .box:after,
.ui.toggle.checkbox.success input:checked ~ label:after {
    box-shadow: 0px 0px 0px 1px #34C759 inset;
}

.ui.toggle.checkbox.success input:checked ~ label {
    font-weight: normal;
}

.ui.checkbox.continuation-provisions-disabled .box:active::before,
.ui.checkbox.continuation-provisions-disabled label:active::before {
    background: #fff;
    border: 2px solid @neutral600;
}
