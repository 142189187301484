/*******************************
         Site Overrides
*******************************/

.menuContainer {
    background: white!important;
    box-shadow: 0px 4px 4px rgba(220, 230, 229, 0.5)!important;
    margin: 0;
    padding: 10px 10px 5px 10px;
    border-bottom: none!important;
    height: 64px;
    position: fixed;
    z-index: 200;
    width: 100%;
}

.menuContainer .ui.menu.text {
    margin: 0;
}

.menuContainer .ui.menu.text .item {
    color: @linkColor;
    font-weight: 600;
}

/* --- MEMBER --- */

.ui.menu.member-menuContainer {
    background: white!important;
    box-shadow: 0px 4px 4px rgba(220, 230, 229, 0.5)!important;
    margin: 0;
    padding: 10px;
    border-bottom: none!important;
}

/* --- EMPLOYER --- */

.ui.menu.employer-portal-menuContainer {
    background: white!important;
    margin: 0;
    padding: 10px;
    border-bottom: none!important;
    z-index: 102;
    height: 64px;
}

.employer-portal-menuContainer .menu-rightColumn{
    display: flex!important;
    background-color: #FFF!important;
    padding: 0!important;
}

.shadow-bottom-border {
    box-shadow: 0px 4px 4px rgba(220, 230, 229, 0.5)!important;
}

.ui.secondary.pointing.menu.employeeRoster .active.item {
    border-color: @voyaDarkBlue;
    border-bottom-width: 6px;
    padding-bottom: 10px;
}

.ui.secondary.pointing.menu .item {
    align-self: unset;
}

.ui.secondary.pointing.menu.adminsTableMenu {
    border-bottom: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    a {
        padding: 0px;
        margin-right: 24px;
        padding-bottom: 4px;
    }
}

.ui.secondary.pointing.menu.adminsTableMenu .active.item {
    border-bottom: 4px solid @persimmon;
}

