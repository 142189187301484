/*******************************
         Site Overrides
*******************************/

.dashed-vertical-divider {
  position: absolute;
  left: 0;
  top: 1rem;
  bottom: 1rem;
  border-left: 1px dashed #E0E0E0;
}

@media only screen and (max-width: 767px) {
  .ui.stackable.grid .dashed-vertical-divider {
    display: none;
  }
}


.employer-portal-menuContainer > .ui.grid:first-of-type {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

.employer-portal-menuContainer > .ui.grid:first-of-type > .column:first-of-type,
.employer-portal-menuContainer > .ui.grid:first-of-type > .column:first-of-type > a.item {padding-left: 0}

.ui.grid > .row > .column.banner-image {
  padding-left: 16px !important;
  padding-right: 0px !important;
}

.ui.grid > .row > .column.education-link-image {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 90%;
}


/* --- Grid Fitted --- */

.ui[class*="fitted"].grid:not(.vertically):not(.horizontally) > .column:not(.row),
.ui[class*="fitted"].grid:not(.vertically):not(.horizontally) > .row > .column,
 .ui[class*="horizontally fitted"].grid > .column:not(.row),
 .ui[class*="horizontally fitted"].grid > .row > .column {
    padding-left: 0em!important;
    padding-right: 0em!important;
}

.ui[class*="fitted"].grid:not(.vertically):not(.horizontally) > .column:not(.row),
.ui[class*="fitted"].grid:not(.vertically):not(.horizontally) > .row,
 .ui[class*="vertically fitted"].grid > .column:not(.row),
 .ui[class*="vertically fitted"].grid > .row {
    padding-top: 0em!important;
    padding-bottom: 0em!important;
}

/* --- Grid Compact --- */

.ui[class*="compact"].grid:not(.vertically):not(.horizontally) > .column:not(.row),
.ui[class*="compact"].grid:not(.vertically):not(.horizontally) > .row > .column,
.ui[class*="horizontally compact"].grid > .column:not(.row),
.ui[class*="horizontally compact"].grid > .row > .column {
    padding-left: 0.5em!important;
    padding-right: 0.5em!important;
}

.ui[class*="compact"].grid:not(.vertically):not(.horizontally) > .column:not(.row),
.ui[class*="compact"].grid:not(.vertically):not(.horizontally) > .row,
.ui[class*="vertically compact"].grid > .column:not(.row),
.ui[class*="vertically compact"].grid > .row {
   padding-top: 0.5em!important;
   padding-bottom: 0.5em!important;
}

/* --- Grid.Row Fitted --- */

.ui.grid > [class*="fitted"].row:not(.vertically):not(.horizontally) > .column,
.ui.grid > [class*="horizontally fitted"].row > .column {
    padding-left: 0em!important;
    padding-right: 0em!important;
}

.ui.grid > [class*="fitted"].row:not(.vertically):not(.horizontally),
.ui.grid > [class*="vertically fitted"].row {
    padding-top: 0em!important;
    padding-bottom: 0em!important;
}


/* --- Grid.Row Compact --- */

.ui.grid > [class*="compact"].row:not(.vertically):not(.horizontally) > .column,
.ui.grid > [class*="horizontally compact"].row > .column{
    padding-left: 0.5em!important;
    padding-right: 0.5em!important;
}

.ui.grid > [class*="compact"].row:not(.vertically):not(.horizontally),
.ui.grid > [class*="vertically compact"].row {
    padding-top: 0.5em!important;
    padding-bottom: 0.5em!important;
}

.ui.grid> .row> .column.mfaFlexEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ui.grid> .row> .column.mfaFlexEnd > button {
    margin-right: 0px!important;
}

.ui.grid.mfaBackupCodesGrid> .row> .column:first-child {
    flex: 1!important;
}

.ui.grid.mfaBackupCodesGrid> .row> .column:last-child {
    flex: 0!important;
    align-content: center;
}

.ui.grid.mfaAuthCodeContainer {
    max-width: 348px;
    margin: 1.5rem auto;
    gap: 8px;
}