/*******************************
    User Variable Overrides
*******************************/

.indentation {
    padding-left: 10px!important;
}

.mfaConfigList.ui.ordered.list {
    margin-left: 0;
}

.mfaConfigList.ui.ordered.list > .item {
    padding-left: 3rem;
    margin-bottom: 32px;
    padding-right: 1.8rem;
}

.mfaConfigList.ui.ordered.list > .item:before {
    background-color: @voyaDarkOrange;
    border-radius: 50px;
    color: @white;
    height: 32px;
    margin-left: -3rem;
    text-align: center;
    width: 32px;
    line-height: 30px;
    font-weight: bold;
}