/*******************************
         Site Overrides
*******************************/

.ui.button {
    font-size: @smallFontSize !important;
    font-weight: bold !important;
}

.ui.disabled.button, .ui.button:disabled {
    background-color: @disabledButtonBkg !important;
    color: #fff;
    opacity: 1 !important;
}

.ui.button.basic.link, .ui.button.basic.link:hover, .ui.button.basic.link:focus {
    padding: unset;
    box-shadow: 0 0 !important;
}

.ui.button.negative {
    background-color: @warning900;
}

.ui.button.employer-rightButton {
    margin-left: 8px
}
