/*******************************
        User Overrides
*******************************/

.ui.accordion .active.content,
.ui.accordion .accordion .active.content {
    font-size: @smallFontSize;
}

.ui.accordion.jb-analytics__accordion .title {
    font-size: 16px !important;
    font-weight: bold;
    padding: 8px !important;
    background: @neutral50;
    border-radius: 8px;
    text-transform: uppercase;
}
