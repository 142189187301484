/*******************************
         Site Overrides
*******************************/

ui.container accountContainer {
    min-height: 280px!important;
}

.accountMfaContainer {
    margin-top: 24px;
}

.ui.container.benefits {
    padding: 0 40px;
}

.employer-accountCreation-container {
    padding-top: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    position: relative;
}

@media (max-width:420px) and (min-width:0px) {
    .employer-accountCreation-container {
        margin-bottom: 400px;
    }
    .employer-createPassword-container {
        margin-bottom: 420px;
    }
}

@media (max-width:600px) and (min-width:421px) {
    .employer-accountCreation-container {
        margin-bottom: 330px;
    }
    .employer-createPassword-container {
        margin-bottom: 350px;
    }
}

@media (max-width:800px) and (min-width:601px) {
    .employer-accountCreation-container {
        margin-bottom: 200px;
    }
    .employer-createPassword-container {
        margin-bottom: 240px;
    }
}

@media (max-width:1000px) and (min-width:801px) {
    .employer-accountCreation-container {
        margin-bottom: 150px;
    }
    .employer-createPassword-container {
        margin-bottom: 180px;
     }
}

@media (min-width:1001px) {
    .employer-accountCreation-container {
        margin-bottom: 130px;
    }
    .employer-createPassword-container {
        margin-bottom: 150px;
     }
}

@media (max-width:420px) and (min-width:0px) {
.employer-accountCreation-container {
    margin-bottom: 400px;
 }
}

@media (max-width:600px) and (min-width:421px) {
.employer-accountCreation-container {
    margin-bottom: 330px;
 }
}

@media (max-width:800px) and (min-width:601px) {
.employer-accountCreation-container {
    margin-bottom: 200px;
 }
}

@media (max-width:1000px) and (min-width:801px) {
.employer-accountCreation-container {
    margin-bottom: 150px;
 }
}

@media (min-width:1001px) {
.employer-accountCreation-container {
    margin-bottom: 130px;
 }
}