/*******************************
         Site Overrides
*******************************/

.ui.label.draft {
    background-color: @sunshade50;
    font-size: @smallFontSize;
    font-weight: normal;
    color: @neutral900;
    display: inline-flex;
        align-items: center;
}

.ui.label.draft .icon{
    color: @sunshade;
    font-size: 9px;
}

.ui.label.open,
.ui.label.under-review,
.ui.label.payment-pending,
.ui.label.approved,
.ui.label.payment-issued {
    background-color: @fog;
    font-size: @smallFontSize;
        font-weight: normal;
        color: @neutral900;
        display: inline-flex;
            align-items: center;
}

.ui.label.open .icon,
.ui.label.under-review .icon,
.ui.label.payment-pending .icon,
.ui.label.payment-issued .icon {
    color: @sunshade;
    font-size: 9px;
}

.ui.label.not-in-good-order {
    background-color: @warning50;
    font-size: @smallFontSize;
        font-weight: normal;
        color: @neutral900;
        display: inline-flex;
            align-items: center;
}

.ui.label.not-in-good-order .icon{
    color: @warning900;
    font-size: 9px;
}

.ui.label.approved .icon{
    color: @blue;
    font-size: 9px;
}

.ui.label.denied {
    background-color: @neutral200;
    font-size: @smallFontSize;
        font-weight: normal;
        color: @neutral900;
        display: inline-flex;
            align-items: center;
}

.ui.label.denied .icon{
    color: black;
    font-size: 9px;
    margin-bottom: 2px;
}

.ui.label-verificationStatus {
    margin-bottom: 20px!important;
    border-radius: 4px;
    font-weight: normal!important;
    font-style: normal!important;
}

.ui.label-bkgSunshade50 {
    background: @sunshade50!important;
}

.ui.label-bkgWarning50 {
    background: @warning50!important;
}

.ui.label.widePadding {
    padding: 0.5em 1.5em !important;
}

.ui.blue.label{
    background-color: @voyaDarkBlue!important;
    border-color: @voyaDarkBlue!important;
}