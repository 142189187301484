/*******************************
         Site Overrides
*******************************/

.hovereable.ui.segment:hover {
    border: 1px solid @focusBlue;
    box-sizing: border-box;
    cursor: pointer;
}

.ui.segment employer-myInfoContainer {
  padding: 0px!important;
  margin-top: 24px!important;
}

.ui.segment.employerAdminsTableContainer {
  padding: 24px!important;
  margin-top: 24px!important;
}

.ui.segment.broker-segment {
    height: 353px;
}

.ui.segment.toast {
    position: fixed;
    bottom: 60px;
    right: 10px;
    border-radius : 4px;
    z-index: 99999;
    padding: 1rem;
}

.ui.segment.toast > :first-child {
    display: flex;
}

.ui.segment.toast  .toast-content {
    padding-left:16px;
    padding-right:8px;
    flex-grow: 1;
    max-width: 330px;
}

.ui.segment.termination-planChanges {
    margin-top: 40px;
    border-radius: 0.5rem;
    padding: 24px 123px 24px 32px;
}

.ui[class*="half padded"].segment {
    padding: 0.5em;
}

.ui[class*="double padded"].segment {
    padding: 2em;
}

.ui[class*="moderate padded"].segment {
    padding: 2.5em;
}

.ui.segment.qle-card {
    border-radius: 0.5rem;
    border: 1px solid @neutral300;
    box-shadow: none;
    padding: 0;
}

.ui.segment.qle-card.warning {
    border-color: @sunshade;
}

.ui.segment.qle-card.error {
    border-color: @warning700;
}

.ui.segment.qle-card .icon {
    flex-shrink: 0;
    width: 56px;
}

.ui.segment.qle-card .icon > img{
    margin-left: 0;
}

.ui.segment.qle-card .content {
    flex-grow: 1;
}

.ui.segment.qle-card .reason {
    margin-bottom: 8px;
}

.ui.segment.qle-card .description {
    font-size: 14px;
}

.ui.segment.qle-card .description .nowrap {
    white-space: nowrap;
}

.ui.segment.qle-card .change-request {
    margin-bottom: 0;
}

.ui.segment.qle-card > div:first-child{
    display: flex;
    padding: 24px;
}

.ui.segment.qle-card .qleFooter {
    border-radius: 0 0 0.5rem 0.5rem;
    display: flex;
    padding: 24px;
    background: @neutral50;
}

.ui.segment.qle-card.warning .qleFooter{
    background: @linen;
}

.ui.segment.qle-card .qleFooter .content{
    width: 100%;
}

.ui.segment.qle-card .qleFooter .content .grid .column{
    width: max-content!important;
}

.ui.segment.qle-card .qleFooter .content .grid .column:nth-child(odd){
    padding-right:80px;
}

.ui.segment.qle-card .qleFooter .qleFooter-label{
    color: @neutral700;
    line-height: 21px;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    white-space: nowrap;
}

.ui.segment.qle-card .qleFooter .qleFooter-value {
    color: @neutral900;
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
}

.ui.segment.noPadding {
    padding: 0;
}

.ui.segment.mfaMethodContainer {
    box-shadow: none;
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin: 0 auto;
}

.ui.segment.mfaCustomBoxQR {
    border-radius: 8px;
    border: 1px solid @neutral300;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.05);
}

.ui.segment.mfaCustomBoxQR p {
    margin-bottom: 0;
}

.ui.segment.mfaSettingsEnable {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: @fog;
    margin: 24px auto;
}

.ui.segment.mfaCloseBackupContent {
    margin: 0 auto!important;
    padding-top: 0;
}

.ui.segment.mfaReminder {
    background-color: @neutral50;
    padding: 12px 16px!important;
    font-size: 12px;
}