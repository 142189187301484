/*******************************
        Site Overrides
*******************************/

.ui.error.message {
    background-color: @warning50;
    color: @neutral900;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    font-size: @smallerFontSize;
}

.ui.info.message {
    color: #276F86;
}

.ui.message.errorBox {
    background-color: @warning50;
    color: @textColor;
    font-size: @smallerFontSize;
    box-shadow: none;
    margin: 0px;
    border: 1px solid @warning50;
}

.ui.warning.message {
    background-color: @linen;
    color: @neutral900;
}

.ui.negative.message{
    background-color: @warning50;
    color: @textColor;
    font-size: @smallerFontSize;
    box-shadow: none;
    border: none;
    border-radius: 0;
}

.ui.message.invalidAttempt {
    padding: 12px 16px;
}