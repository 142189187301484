/*******************************
         Site Overrides
*******************************/
.icon-small {
    font-size: 0.4em!important;
    margin: 0.4em!important;
}

.icon-vertical-allign {
    position: abolute!important;
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%,-50%)!important;
}

.icon-primary-sunshade {
    color: @sunshade !important;
}

.icon-primary-persimmon {
    color: @persimmon !important;
}

.visibility-toggle-icon {
    color: @neutral700;
}

.toast i.icon.green {
    color: @pineGreen!important;
}

i.mfaVerifiedIcon {
    color: @eucalyptus;
}